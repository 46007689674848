<template>
  <div class="roomList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>活动Id:</span>
      <el-input
        placeholder="请输入活动Id"
        v-model.trim="parmes.connectId"
        v-if="type == 'link'"
      ></el-input>
      <el-input
        placeholder="请输入活动Id"
        v-model.trim="parmes.advanceId"
        v-else
      ></el-input>
      <span>活动名称:</span>
      <el-input
        placeholder="请输入活动名称"
        v-model.trim="parmes.title"
      ></el-input>
      <span>活动状态:</span>
      <el-select
        clearable
        @clear="onClear(0)"
        v-model="parmes.status"
        placeholder="请选择"
      >
        <el-option
          v-for="item in statusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="onSearch">搜索</el-button>
      <!-- <el-button type="primary" @click="$router.go(-1)">返回</el-button> -->
      <template v-if="type == 'link'">
      <el-button
        type="primary"
        @click="$router.push({ path: '/addActivityLinking', query: { type } })"
        v-if="$store.state.powerList.indexOf('connect:info:list:add') !== -1"
        >添加活动</el-button
      >
      
      </template>
      <template v-else>
      <el-button
        type="primary"
        @click="$router.push({ path: '/addActivityLinking', query: { type } })"
        v-if="$store.state.powerList.indexOf('advance:info:list:add') !== -1"
        >添加活动</el-button
      >
      </template>
    </div>
    <!-- -------表格----- -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      @selection-change="onSelectChange"
    >
      <!-- <el-table-column align="center" type="selection" width="55"></el-table-column> -->
      <el-table-column align="center" prop="detp" label="排序" width="120">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button v-else class="button-new-tag" @click="onChangeSort(row)">
            {{ row.dept }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="connectId"
        label="活动ID"
        width="120"
        v-if="type == 'link'"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="advanceId"
        label="活动ID"
        width="120"
        v-else
      >
      </el-table-column>
      <el-table-column align="center" prop="title" label="活动名称">
      </el-table-column>
      <el-table-column
        align="center"
        prop="activeTime"
        label="活动时间"
        width="320"
      >
      </el-table-column>
      <el-table-column align="center" prop="days" label="连住天数" v-if="type == 'link'">
      </el-table-column>
      <el-table-column align="center" prop="days" label="提前预订天数" v-else>
      </el-table-column>
      <!-- <el-table-column align="center" prop="quotaCount" label="限购数量">
        </el-table-column> -->
      <el-table-column align="center" prop="isCoupon" label="是否可用优惠券">
      </el-table-column>
      <el-table-column align="center" prop="isUser" label="是否可用会员等级">
      </el-table-column>
      <el-table-column align="center" prop="strStatus" label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="{ row }">
          <template v-if="type == 'link'">
            <el-button
              size="mini"
              @click="onUpAndDownRow(row)"
              v-if="
                (row.status == 1 || row.status == 2) &&
                $store.state.powerList.indexOf('connect:info:list:update') !==
                  -1
              "
              type="primary"
              >使失效
            </el-button>
            <el-button
              size="mini"
              @click="onUpAndDownRow(row)"
              v-else-if="
                row.status == 4 &&
                $store.state.powerList.indexOf('connect:info:list:update') !==
                  -1
              "
              type="primary"
              >启用
            </el-button>
            <el-button
              size="mini"
              type="primary"
              v-if="
                $store.state.powerList.indexOf('connect:info:list:edit') !== -1
              "
              @click="
                $router.push({
                  path: '/addActivityLinking?type=link',
                  query: { connectId: row.connectId },
                })
              "
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="onDelRow(row)"
              v-if="
                $store.state.powerList.indexOf('connect:info:list:delete') !==
                -1
              "
              >删除</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="
                $router.push({
                  path: '/roomListLinking',
                  query: { connectId: row.connectId },
                })
              "
              v-if="
                $store.state.powerList.indexOf('connect:room:list:select') !==
                -1
              "
              >客房管理</el-button
            >
          </template>
          <template v-else>
            <el-button
              size="mini"
              @click="onUpAndDownRow(row)"
              v-if="
                (row.status == 1 || row.status == 2) &&
                $store.state.powerList.indexOf('advance:info:list:update') !==
                  -1
              "
              type="primary"
              >使失效
            </el-button>
            <el-button
              size="mini"
              @click="onUpAndDownRow(row)"
              v-else-if="
                row.status == 4 &&
                $store.state.powerList.indexOf('advance:info:list:update') !==
                  -1
              "
              type="primary"
              >启用
            </el-button>
            <el-button
              size="mini"
              type="primary"
              v-if="
                $store.state.powerList.indexOf('advance:info:list:edit') !== -1
              "
              @click="
                $router.push({
                  path: '/addActivityLinking?type=reserve',
                  query: { advanceId: row.advanceId },
                })
              "
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="onDelRow(row)"
              v-if="
                $store.state.powerList.indexOf('advance:info:list:delete') !==
                -1
              "
              >删除</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="
                $router.push({
                  path: '/commodityManagement',
                  query: { advanceId: row.advanceId},
                })
              "
              v-if="
                $store.state.powerList.indexOf('advance:prod:list:select') !==
                -1
              "
              >商品管理</el-button
            >
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="parmes.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="parmes.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
  <script>
//   import {  getActiveList } from "../../api/hotelList";
import {
  modifyActiveStatus,
  getActiveList,
  deleteActive,
  modifyActiveDept,
  getActiveListReserve,
  modifyActiveStatusReserve,
  deleteActiveReserve,
  modifyActiveDeptReserve,
} from "../../api/room";
import { isActivity } from "../../api/groupBuy";
import { isActivityKill } from "../../api/seckillInfo";
import { createEr2 } from "../../api/erCode";
export default {
  name: "roomList",
  data() {
    return {
      roomAddress: "",
      showAddress: false,
      QRcodeSize: "",
      codeVisible: false,
      roomId: "",
      imgUrl: "",
      tableData: [],
      pagination: {},
      ids: null,
      parmes: {
        currentPage: 1,
        pageSize: 5,
        connectId: null,
        title: "",
        status: null,
        advanceId: null,
      },
      hotelList: [],
      statusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "未开始",
        },
        {
          value: 3,
          label: "已结束",
        },
        {
          value: 4,
          label: "已失效",
        },
      ],
      isShowOptions: [
        {
          value: -2,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
      sellWayList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.getActiveList();
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type;
      this.getActiveList();
    },
  },
  methods: {
    //   onShowAddress(id) {
    //     this.roomAddress =
    //       "wanXiaoDian/pages/hotel/hotelDetail/hotelDetail?scene=" + id + "_0";
    //     this.showAddress = true;
    //   },

    copyinviteUrl() {
      this.$nextTick(() => {
        var range = document.createRange();
        range.selectNode(document.getElementById("wrapper"));
        var selection = window.getSelection();
        if (selection.rangeCount > 0) selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        this.$message({ message: "复制成功", type: "success" }); //此处为结合使用element-ui的Message 消息提示组件
      });
    },

    creatCode(id) {
      this.roomId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr2({
          hotelId: this.roomId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    async onGOSecKill(roomId) {
      const { data } = await isActivityKill({
        prodType: 1,
        prodId: roomId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addSeckill/0/${roomId}/${1}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    async onGOGroupBuy(roomId) {
      const { data } = await isActivity({
        prodType: 1,
        prodId: roomId,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.$router.push(`/addGroupBuy/0/${roomId}/${1}`);
      } else {
        this.$message.error(data.msg);
      }
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },

    onDelet(row) {},
    async getActiveList() {
      let demaad = this.type == "link" ? getActiveList : getActiveListReserve;
      const { data } = await demaad(this.parmes);
      this.pagination = data.pagination;
      // this.hotelList = data.data;
      data.list.map((item) => {
        item.inputVisible = false;
        item.activeTime = item.beginTime + "--" + item.endTime;
      });
      this.tableData = data.list;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    onSearch() {
      this.getActiveList();
    },
    onClear(status) {
      this.parmes.status = status;
    },
    onDelRow(row) {
      this.$confirm(`是否要删除这个活动?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let demaad = this.type == "link" ? deleteActive : deleteActiveReserve;
          let data = {};
          if (this.type == "link") {
            data = await demaad({
              connectId: row.connectId,
            });
          } else {
            data = await demaad({
              advanceId: row.advanceId,
            });
          }
          if (data.code == 0) {
            this.getActiveList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.ids = null;
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onUpAndDownRow(row) {
      if (row.status == 1 || row.status == 2) {
        this.$confirm(`是否要操作这条数据?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            console.log(6555);
            let demaad =
              this.type == "link"
                ? modifyActiveStatus
                : modifyActiveStatusReserve;
            let data = {};
            if (this.type == "link") {
              data = await demaad({
                connectId: row.connectId,
                status: 4,
              });
            } else {
              data = await demaad({
                advanceId: row.advanceId,
                status: 4,
              });
            }
            console.log(data);
            if (data.data.code == 0) {
              this.getActiveList();
              this.$message({
                type: "success",
                message: data.data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else if (row.status == 4) {
        this.$confirm(`是否要操这条数据?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let demaad =
              this.type == "link"
                ? modifyActiveStatus
                : modifyActiveStatusReserve;
            let data = {};
            if (this.type == "link") {
              data = await await demaad({
                connectId: row.connectId,
                status: 1,
              });
            } else {
              data = await demaad({
                advanceId: row.advanceId,
                status: 1,
              });
            }
            if (data.data.code == 0) {
              this.getActiveList();
              this.$message({
                type: "success",
                message: data.data.msg,
              });
            } else {
              this.$message({
                type: "error",
                message: data.data.msg,
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.roomId);
      });
      this.ids = arr;
    },
    async handleInputConfirm(row) {
      let demaad =
        this.type == "link" ? modifyActiveDept : modifyActiveDeptReserve;
      if (this.type == "link") {
        const { data } = await demaad({
          connectId: row.connectId,
          dept: row.dept,
        });
      } else {
        const { data } = await demaad({
          advanceId: row.advanceId,
          dept: row.dept,
        });
      }
      row.inputVisible = false;
      this.getActiveList();
    },
    handleSizeChange(num) {
      console.log(num);
      this.parmes.pageSize = num;
      this.getActiveList();
    },
    handleCurrentChange(num) {
      console.log(num);
      
      this.parmes.currentPage = num;
      this.getActiveList();
    },
  },
};
</script>
  <style lang="less" scoped>
.roomList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-input {
      width: 200px;
    }
    span {
      margin: 0 20px;
    }
    .el-button {
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .btn {
    margin-top: 50px;
  }
}
</style>
  